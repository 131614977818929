<template>
  <button
    :class="[themes[theme], sizes[size]]"
    class="transition-ease-in-out text-sm font-semibold leading-none focus:outline-none focus-visible:outline-none disabled:pointer-events-none"
    type="button"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "CoreButtonBase",
  props: {
    theme: {
      type: String,
      default: "",
    },
    size: {
      default: "normal",
    },
  },
  data() {
    return {
      themes: {
        default:
          "bg-primary text-white rounded-full border border-primary font-bold hover:bg-white hover:text-primary hover:border-primary",
        primary:
          "bg-primary text-white rounded-full border border-primary font-bold hover:bg-white hover:text-primary hover:border-primary",
        primaryOutline:
          "bg-transparent text-primary rounded-full border border-primary font-bold hover:bg-primary hover:text-white",
        secondary:
          "bg-warning text-primary rounded-full border border-warning font-bold hover:bg-primary hover:text-white hover:border-white",
        blue: "bg-blue text-white rounded-full border border-blue font-bold hover:bg-white hover:text-blue hover:border-blue",
        white:
          "bg-white text-primary rounded-full border border-primary font-bold hover:bg-primary hover:text-white hover:border-white disabled:opacity-50 disabled:grayscale",
        sportPrimary:
          "bg-[#FF1B5E] text-white rounded-full border border-[#FF1B5E] font-bold hover:bg-white hover:text-primary hover:border-primary",
        domPrimary:
          "bg-[#FFD508] text-primary rounded-full border border-[#FFD508] font-bold hover:bg-white hover:text-primary hover:border-primary",
        domSecondary:
          "bg-domSecondary text-white rounded-full border border-domSecondary font-bold hover:bg-white hover:text-domSecondary hover:border-domSecondary",
        link: "text-primary hover:brightness-50 hover:underline",
        domSecondaryOutline:
          "bg-white text-domSecondary rounded-full border border-domSecondary font-bold hover:bg-domSecondary hover:text-white hover:border-white",
        disabled: "text-darkestGray border-0",
      },
      sizes: {
        sm: "px-2 min-h-[28px]",
        normal: "px-4 min-h-[42px]",
        lg: "px-6 min-h-[42px]",
        xl: "px-8 min-h-[42px]",
        dbl: "px-6 min-h-[42px] ",
        icon: "px-3 min-h-[54px] ",
      },
    };
  },
};
</script>
