import http from "@/utils/axios.js";
import { notify } from "@kyvg/vue3-notification";
import {
  getDecryptedFromSessionStorage,
  getFromSessionStorage,
  removeFromLocalStorage,
  removeFromSessionStorage,
  saveEncryptedToSessionStorage,
  saveToSessionStorage,
} from "@/utils/localstorage";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    patient: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirm: "",
      code: "",
      phone_number: "",
      date: "",
      pesel: "",
      isNoPesel: false,
      accept_terms: false,
      accept_marketing: false,
      accept_processing: false,
      gender: null,
    },
    oldAccountCredentials: getDecryptedFromSessionStorage(
      "login/old_account_credentials",
      null
    ),
    type: getFromSessionStorage("login/type", null),
    wantMigrateData: getFromSessionStorage("login/want_migrate_data", false),
    errors: [],
  },
  getters: {
    getPatient: (state) => state.patient,
    getErrors: (state) => state.errors,
    getType: (state) => state.type,
    getOldAccountCredentials: (state) => state.oldAccountCredentials,
    getWantMigrateData: (state) => state.wantMigrateData,
  },
  mutations: {
    setPatient(state, patient) {
      Object.assign(state.patient, patient);
    },
    setLoginToken(state, token) {
      saveToSessionStorage("authtoken", token);
      http.defaults.headers["Authorization"] = "Bearer " + token;
    },
    setOldAccountCredentials(state, credentials) {
      state.oldAccountCredentials = credentials;
      saveEncryptedToSessionStorage(
        "login/old_account_credentials",
        credentials
      );
    },
    setLoginType(state, type) {
      state.type = type;
      saveToSessionStorage("login/type", type);
    },
    setWantMigrateData(state, wantMigrateData) {
      state.wantMigrateData = wantMigrateData;
      saveToSessionStorage("login/want_migrate_data", wantMigrateData);
    },
    resetPatient(state) {
      state.patient = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: "",
        code: "",
        phone_number: "",
        date: "",
        pesel: "",
        isNoPesel: false,
        accept_terms: false,
        accept_marketing: false,
        gender: null,
      };
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  actions: {
    resetOldAccountData({ commit }) {
      commit("setOldAccountCredentials", null);
      commit("setLoginType", null);
      commit("setWantMigrateData", false);
    },
    async logout({ commit }) {
      try {
        await http.post("/logout");
        removeFromSessionStorage("authtoken");
        removeFromLocalStorage("cart");
        commit("resetPatient");
        commit("user/resetCurrentUser", null, { root: true });
        commit("user/setShowConsentExaminationsDoctorsModalFalse", null, {
          root: true,
        });
        commit("cart/clearAll", null, { root: true });
        commit("checkout/clearData", null, { root: true });
        commit("checkout/clearPatients", null, { root: true });
        commit("checkout/clearPersonalData", null, { root: true });
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
    attachFamilyToPatients({ commit }, { family, personalData }) {
      for (let children of family) {
        let c = {
          id: children.id,
          age: children.age,
          birth_date: moment(children.birth_date).format("DD.MM.YYYY"),
          country: personalData.user.country,
          country_code: personalData.user.country_code,
          first_name: children.first_name,
          last_name: children.last_name,
          isNoPesel: !children.pesel,
          pesel: children.pesel,
          phone_number: null,
          sex: children.gender,
        };
        commit("checkout/savePatient", c, { root: true });
      }
    },
    async loginPatient({ commit, state, dispatch }) {
      try {
        const { data } = await http.post("/patient/login", state.patient);
        commit("setLoginType", data.type);

        switch (data.type) {
          case "old_system":
            await dispatch("loginByOldSystem");
            break;
          default:
            await dispatch("loginToSystem", data);
            break;
        }
        commit("clearErrors");
        if (data.type !== "old_system") {
          let res = await http.get("family/children", state.patient);

          if (res.data.length > 0) {
            dispatch("attachFamilyToPatients", {
              family: res.data,
              personalData: data,
            });
          }
        }

        return data;
      } catch ({ response }) {
        if (response && response.status === 422) {
          commit("setErrors", response.data.errors);
        } else if (
          response &&
          response.status === 403 &&
          response.data.message === "error.auth.age_not_allowed"
        ) {
          commit("modals/openModal", "loginAgeExceptionModal", { root: true });
        }

        throw Error();
      }
    },
    loginByOldSystem({ commit, state }) {
      commit("setOldAccountCredentials", {
        email: state.patient.email,
        password: state.patient.password,
      });
    },
    async loginToSystem({ commit, dispatch }, data) {
      commit("setLoginToken", data.token);
      await dispatch("prepareDataForUser", data.user);
    },
    async prepareDataForUser({ commit, dispatch }, user) {
      commit("user/setCurrentUser", user, { root: true });

      dispatch("checkout/createOrUpdatePatient", user, {
        root: true,
      });

      await dispatch("user/fetchAccountData", null, { root: true });
      dispatch("checkout/setUserDefaultData", null, { root: true });

      if (user.default_facility) {
        commit("cart/setSelectedFacility", user.default_facility, {
          root: true,
        });
      }
    },
    async registerPatient({ commit, state }, withValidateUser = false) {
      commit("clearErrors");

      return new Promise((resolve, reject) => {
        return http
          .post("/patient/send-verification-token", {
            ...state.patient,
            withValidateUser,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422) {
              commit("setErrors", response.data.errors);
            }

            if (
              response &&
              response.status === 403 &&
              response.data.message === "error.auth.exist_in_old_system"
            ) {
              commit("modals/openModal", "userExistInOldSystemExceptionModal", {
                root: true,
              });
            }

            if (response && response.status === 429) {
              notify({
                type: "danger",
                text: response.data.message,
                duration: 5000,
              });
            }

            reject();
          });
      });
    },
    async verifyPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/verify-code", state.patient)
          .then(() => {
            commit("clearErrors");
            resolve();
          })
          .catch(({ response }) => {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
    async resendCodePatient({ state, commit }) {
      await http
        .post("/patient/send-verification-token", state.patient)
        .then(() => {
          notify({
            type: "info",
            text: "messages.code.resend",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422) {
            commit("setErrors", response.data.errors);
            notify({
              type: "danger",
              text: "messages.code.resend_error",
              duration: 5000,
            });
          }

          if (response && response.status === 429) {
            notify({
              type: "danger",
              text: response.data.message,
              duration: 5000,
            });
          }
        });
    },
    async firstLoginPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/first-login", state.patient)
          .then(({ data }) => {
            saveToSessionStorage("authtoken", data.token);
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
  },
};
