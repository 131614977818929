import { createRouter, createWebHistory } from "vue-router";
import {
  getFromSessionStorage,
  removeFromSessionStorage,
} from "@/utils/localstorage";
import http from "@/utils/axios";
import store from "@/store";

const mainPageBreadCrumb = [
  {
    name: "layouts.main_page",
    external_link: process.env.VUE_APP_LANDING_PAGE,
  },
];

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Examinations" },
    component: () =>
      import(/* webpackChunkName: "catalog-layout" */ "@/layouts/Catalog.vue"),
    children: [
      {
        path: "/examinations/:categoryId?",
        props: true,
        name: "Examinations",
        meta: {
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.catalog" }],
          depth: 1,
        },
        component: () =>
          import(
            /* webpackChunkName: "examinations" */ "@/views/Examinations.vue"
          ),
      },
      {
        path: "/packages/:categoryId?",
        props: true,
        name: "Packages",
        meta: {
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.catalog" }],
          depth: 2,
        },
        component: () =>
          import(/* webpackChunkName: "packages" */ "@/views/Packages.vue"),
      },
      {
        path: "/own-package",
        name: "OwnPackages",
        meta: {
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.catalog" }],
          depth: 3,
        },
        component: () =>
          import(/* webpackChunkName: "packages" */ "@/views/OwnPackage.vue"),
      },
      {
        path: "/search",
        name: "SearchServices",
        meta: {
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.search" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "search-service" */ "@/views/SearchServices.vue"
          ),
      },
    ],
  },

  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "layout" */ "@/layouts/User.vue"),
    children: [
      {
        path: "payment-success",
        name: "PaymentSuccess",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "payment-success" */ "@/views/Payment/Success.vue"
          ),
      },
      {
        path: "payment-error",
        name: "PaymentError",
        component: () =>
          import(
            /* webpackChunkName: "payment-error" */ "@/views/Payment/Error.vue"
          ),
      },
      {
        path: "about-us",
        name: "About",
        meta: {
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.about_us" }],
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/About.vue"),
      },
      {
        path: "cart",
        name: "Cart",
        meta: {
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.cart" }],
        },
        component: () =>
          import(/* webpackChunkName: "checkout" */ "@/views/Checkout.vue"),
      },
      {
        path: "retry-payment/:id",
        name: "RetryPayment",
        meta: {
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "layouts.results", link: "Results" },
            { name: "layouts.retry_payment" },
          ],
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ "@/views/Payment/RetryPayment.vue"
          ),
      },
      {
        path: "referral-payment/:id",
        name: "ReferralPayment",
        meta: {
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "layouts.results", link: "Results" },
            { name: "layouts.doctor_referral" },
          ],
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ "@/views/Payment/RetryPayment.vue"
          ),
      },
      //@/views/Checkout.vue
      {
        path: "error",
        name: "Error",
        meta: {
          breadcrumb: [...mainPageBreadCrumb, { name: "not_found_page.error" }],
        },
        component: () =>
          import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
      },
      {
        path: "terms-of-use",
        name: "TermsOfUse",
        meta: {
          breadcrumb: [...mainPageBreadCrumb, { name: "documents.terms" }],
        },
        component: () =>
          import(/* webpackChunkName: "terms" */ "@/views/TermsOfUse.vue"),
      },
      {
        path: "privacy-policy",
        name: "PrivacyPolicy",
        meta: {
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "documents.privacy_policy" },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "privacy-policy" */ "@/views/PrivacyPolicy.vue"
          ),
      },
      {
        path: "register",
        name: "RegisterPatient",
        meta: {
          requiresAuth: false,
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.registration" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "register" */ "@/views/Auth/Patient/Register.vue"
          ),
      },
      {
        path: "code",
        name: "AuthCodePatient",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "code" */ "@/views/Auth/Patient/Code.vue"
          ),
      },
      {
        path: "login",
        name: "LoginPatient",
        meta: {
          requiresAuth: false,
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.login" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/views/Auth/Patient/Login.vue"
          ),
      },
      {
        path: "login/migration-type",
        name: "LoginPatientMigrationType",
        meta: {
          requiresAuth: false,
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.login" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "login-migration-type" */ "@/views/Auth/Patient/LoginMigrationType.vue"
          ),
      },
      {
        path: "login/migration-phone",
        name: "LoginPatientMigrationPhone",
        meta: {
          requiresAuth: false,
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.login" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "login-migration-phone" */ "@/views/Auth/Patient/LoginMigrationPhone.vue"
          ),
      },
      {
        path: "login/accept-terms",
        name: "LoginPatientAcceptTerms",
        meta: {
          requiresAuth: true,
          smsVerified: 1,
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.login" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "login-accept-terms" */ "@/views/Auth/Patient/AcceptTerms.vue"
          ),
      },
      {
        path: "login/change-password",
        name: "LoginPatientChangePassword",
        meta: {
          requiresAuth: true,
          smsVerified: 1,
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.login" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "login-reset-password" */ "@/views/Auth/Patient/ResetPassword.vue"
          ),
      },
      {
        path: "password/email",
        name: "PasswordEmail",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "password-email" */ "@/views/Auth/Password/Email.vue"
          ),
      },
      {
        path: "password/reset/:code",
        name: "PasswordReset",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "password-reset" */ "@/views/Auth/Password/Reset.vue"
          ),
      },
      {
        path: "complete-data",
        name: "AuthPatientCompletionOfData",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "complete-data" */ "@/views/Auth/Patient/CompletionOfData.vue"
          ),
      },

      {
        path: "test-results",
        name: "TestResults",
        meta: {
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "referrals.examination_findings" },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "test-results" */ "@/views/TestResults.vue"
          ),
      },

      // SECURED ZONE

      {
        path: "orders",
        name: "Results",
        meta: {
          requiresAuth: true,
          smsVerified: 1,
          breadcrumb: [...mainPageBreadCrumb, { name: "layouts.results" }],
        },
        component: () =>
          import(/* webpackChunkName: "results" */ "@/views/User/Results.vue"),
      },

      {
        path: "orders/archive",
        name: "OrdersArchive",
        meta: {
          requiresAuth: true,
          smsVerified: 1,
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "layouts.results", link: "Results" },
            { name: "your_referrals.archive" },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "orders-archive" */ "@/views/User/OrdersArchive.vue"
          ),
      },

      {
        path: "referral/:id/orders",
        name: "ReferralDetail",
        meta: {
          requiresAuth: true,
          smsVerified: 1,
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "layouts.results", link: "Results" },
            { name: "layouts.order_detail" },
          ],
        },
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "results" */ "@/views/User/ReferralDetail.vue"
          ),
      },

      {
        path: "referral/:id/results",
        name: "ReferralResults",
        meta: {
          requiresAuth: true,
          smsVerified: 1,
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "layouts.results", link: "Results" },
            { name: "referrals.examination_findings" },
            { name: ":number" },
          ],
        },
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "referral-results" */ "@/views/User/ReferralResults.vue"
          ),
      },

      {
        path: "referral/:id/preview",
        name: "Preview",
        meta: {
          requiresAuth: true,
          smsVerified: 1,
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "layouts.results", link: "Results" },
            { name: ":number" },
          ],
        },
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(/* webpackChunkName: "preview" */ "@/views/User/Preview.vue"),
      },

      {
        path: "paid/:code",
        name: "Paid",
        props: true,
        component: () =>
          import(/* webpackChunkName: "paid" */ "@/views/Payment/Success.vue"),
      },

      // ERRORS
      {
        path: "/error/:code",
        name: "ErrorCode",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "errors-codes" */ "@/views/Errors/ErrorCode.vue"
          ),
      },

      {
        path: "/:catchAll(.*)",
        component: () =>
          import(/* webpackChunkName: "not-found" */ "@/views/NotFound.vue"),
      },
    ],
  },

  {
    path: "/account",
    meta: {
      requiresAuth: true,
      smsVerified: 1,
      breadcrumb: [...mainPageBreadCrumb, { name: "layouts.account" }],
    },
    component: () =>
      import(/* webpackChunkName: "account" */ "@/layouts/UserSettings.vue"),
    children: [
      {
        path: "",
        name: "Account",
        meta: {
          depth: 1,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/User/Account/Personal.vue"
          ),
      },
      {
        path: "addresses-and-invoices",
        name: "Account.AddressesAndInvoices",
        meta: {
          depth: 2,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/User/Account/AddressesAndInvoices.vue"
          ),
      },
      {
        path: "collection-points",
        name: "Account.CollectionPoints",
        meta: {
          depth: 3,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/User/Account/CollectionPoints.vue"
          ),
      },
      {
        path: "childrens",
        name: "Account.Childrens",
        meta: {
          depth: 4,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/User/Account/Children.vue"
          ),
      },
      {
        path: "doctors",
        name: "Account.Doctors",
        meta: {
          depth: 5,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/User/Account/Doctors.vue"
          ),
      },
      {
        path: "your-consents",
        name: "Account.YourConsents",
        meta: {
          depth: 5,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "@/views/User/Account/YourConsents.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (window.location !== window.parent.location) return next();

  store.commit("auth/clearErrors");

  const token = getFromSessionStorage("authtoken", null);

  if (!token) {
    setTimeout(() => {
      store.commit("loader/userLoaded");
    }, 500);
  }

  if (to.meta.requiresAuth && !token) {
    if (to.name == "Results") {
      next({ name: "LoginPatient", query: { returnURL: to.href } });
    } else if (to.name !== "PatientExaminations") {
      next({ name: "LoginPatient" });
    }
  }

  if (token) {
    if (!store.getters["user/getCurrentUser"]) {
      await store
        .dispatch("user/fetchCurrentUser")
        .then(() => {
          setTimeout(() => {
            store.commit("loader/userLoaded");
          }, 200);
        })
        .catch(({ response }) => {
          if (response && response.status === 401) {
            removeFromSessionStorage("authtoken");
            return next({ name: "LoginPatient" });
          }
        });
    }
    let res = await http.get(
      "family/children",
      store.getters["user/getCurrentUser"]
    );

    if (res.data.length > 0) {
      store.dispatch("auth/attachFamilyToPatients", {
        family: res.data,
        personalData: {
          user: store.getters["user/getCurrentUser"],
        },
      });
    }

    let role = store.getters["user/getCurrentUserRole"];
    let sms = store.getters["user/getCurrentUserVerified"];
    let shouldAcceptTerms =
      store.getters["user/getCurrentUserShouldAcceptTerms"];
    let isEnabledLoginByOldSystem = store.getters["features/isEnabled"](
      "login_by_old_system"
    );
    let shouldResetPassword =
      store.getters["user/getCurrentUserShouldResetPassword"];

    if (to.meta.requiresAuth === false) {
      return next({ name: "Account" });
    }

    if (to.meta.requiresAuth && to.meta.smsVerified !== sms) {
      if (role === "patient") {
        if (to.name === "AuthCodePatient") {
          return next({ name: "Account" });
        }
        return next({ name: "AuthCodePatient" });
      }
      return next();
    }

    if (isEnabledLoginByOldSystem && shouldAcceptTerms) {
      if (to.name !== "LoginPatientAcceptTerms") {
        return next({ name: "LoginPatientAcceptTerms" });
      } else {
        return next();
      }
    }

    if (
      isEnabledLoginByOldSystem &&
      shouldResetPassword &&
      to.name !== "LoginPatientChangePassword"
    ) {
      return next({ name: "LoginPatientChangePassword" });
    }

    return next();
  }

  if (!token && to.name === "PatientExaminations") {
    await http
      .get(`is-register/${to.params.id}`)
      .then(({ data }) => {
        return next({
          name: data.is_register ? "LoginPatient" : "RegisterPatient",
        });
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  next();
});

export default router;
